import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { TenantService } from '../../../services';
import { filter, map } from 'rxjs';

/**
 * Checks if the currently selected tenant is the virtual see-all tenant.
 * In that case, the guard stops navigation to pages that do not support the tenant.
 */
@Injectable()
export class SeeAllTenantGuard {
  public latestChildRoute: ActivatedRouteSnapshot | undefined;

  constructor(
    private readonly tenantService: TenantService,
    private readonly router: Router
  ) {
    this.tenantService.selectedTenantIdentifier
      .pipe(
        filter((tenantIdentifier: string) => {
          return (
            tenantIdentifier === 'virtual-see-all' &&
            !!this.latestChildRoute &&
            !this.latestChildRoute.routeConfig?.data?.['virtualSeeAllTenantSupported']
          );
        }),
        map(() =>
          this.router.navigateByUrl(
            this.router.parseUrl(`see-all-tenant-unsupported?redirect=/${this.getLatestResolvedUrl()}`)
          )
        )
      )
      .subscribe();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot) {
    this.latestChildRoute = childRoute;
    const currentTenant = this.tenantService.selectedTenant.value;

    if (currentTenant.identifier === 'virtual-see-all') {
      const isVirtualSeeAllTenantSupported: boolean = childRoute.routeConfig?.data?.[
        'virtualSeeAllTenantSupported'
      ] as boolean;
      /**
       * Existence of childRoute.routeConfig?.loadChildren is checked, because we lazy loads view modules, this
       * first the `childRoute.routeConfig?.data?` will be undefined as we do not define any data in the `{@link AppRoutingModule}`.
       * After the module is loaded, then data available as it is defined in the corresponding routing-module file.
       */
      if (!childRoute.routeConfig?.loadChildren && !isVirtualSeeAllTenantSupported) {
        return this.router.parseUrl(`see-all-tenant-unsupported?redirect=/${this.getLatestResolvedUrl()}`);
      }
    }

    return true;
  }

  /** Angular router does not provide the resolved url directly, thus it must be constructed manually. */
  private getLatestResolvedUrl(): string | undefined {
    return this.latestChildRoute?.pathFromRoot
      ?.map(routeSnapshot => routeSnapshot.url.map(segment => segment.toString()).join('/'))
      .join('/');
  }
}
