import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BannerContainerComponent } from '../../../shared/components';
import { TenantService } from '../../../../services';
import { Subject, takeUntil, tap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

/**
 * Presents a simple information page which informs the user
 * that the chosen page does not support the virtual see all tenant.
 */
@Component({
  templateUrl: `./see-all-tenant-unsupported-page.component.html`,
  styleUrls: ['./see-all-tenant-unsupported-page.component.scss'],
  standalone: false,
})
export class SeeAllTenantUnsupportedPageComponent implements OnInit, OnDestroy {
  @ViewChild(BannerContainerComponent, { static: true })
  public bannerContainer!: BannerContainerComponent;

  private onDestroy = new Subject<void>();

  constructor(
    private readonly tenantService: TenantService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.bannerContainer.showError(
      `The requested view doesn't support the virtual "see all" tenant. Please select a valid tenant and try again.`,
      {
        closable: false,
      }
    );

    this.tenantService.selectedTenantIdentifier
      .pipe(
        takeUntil(this.onDestroy),
        tap(identifier => {
          if (identifier !== 'virtual-see-all') {
            this.route.queryParams.subscribe(params => {
              if (params['redirect']) {
                void this.router.navigate([params['redirect']]);
              }
            });
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.onDestroy.next(undefined);
    this.onDestroy.complete();
  }
}
