import { Component, OnInit, ViewChild } from '@angular/core';
import { BannerContainerComponent } from '../../../shared/components';

/**
 * Presents a simple information page that user does not have right to access page.
 */
@Component({
  selector: 'app-forbidden-page',
  templateUrl: `./forbidden-page.component.html`,
  styleUrls: ['./forbidden-page.component.scss'],
  standalone: false,
})
export class ForbiddenPageComponent implements OnInit {
  @ViewChild(BannerContainerComponent, { static: true })
  public bannerContainer!: BannerContainerComponent;

  ngOnInit(): void {
    this.bannerContainer.showError(`You have no permission to access the selected resource.`, {
      closable: false,
    });
  }
}
