<app-anonymous-view-box-component>
  <div class="content">
    <h1>Sign in</h1>
    <p>You can sign in to the ARTEMIS Platform with your Microsoft work account provided by Alteo.</p>
    <img
      role="button"
      tabindex="0"
      (click)="login()"
      (keydown)="login()"
      src="/assets/images/ms-symbollockup_signin_dark.svg"
      alt="Sign in button"
      class="sign-in-button"
    />
  </div>
</app-anonymous-view-box-component>
